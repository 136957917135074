import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionLogoList } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Flex, Heading, Image } from '../../../atoms'
import { StaticImage } from 'gatsby-plugin-image'

const LogoList: React.FC<SectionLogoList> = (props) => {
  const { id, sectionId, headline, logos, withDecorations } = props

  return (
    <Box as={'section'} id={sectionId || id} overflow={withDecorations ? 'hidden' : undefined}>
      <Box my={SECTION_MARGIN} py={[4, 5, 6]}>
        <Grid>
          <Box position={'relative'}>
            {withDecorations && (
              <>
                <Box display={['none', 'none', 'block']} position={'absolute'} zIndex={0} left={'-247px'} top={-110}>
                  <StaticImage
                    formats={['auto']}
                    draggable={false}
                    src={'../../../images/grid.png'}
                    alt={''}
                    placeholder={'none'}
                    width={247}
                  />
                </Box>
                <Box display={['none', 'none', 'block']} position={'absolute'} zIndex={0} right={'-500px'} bottom={-40}>
                  <StaticImage
                    formats={['auto']}
                    draggable={false}
                    src={'../../../images/ellipse_bl.png'}
                    alt={''}
                    placeholder={'none'}
                    width={500}
                  />
                </Box>
              </>
            )}

            <Box position={'relative'} zIndex={1}>
              <Row center={'xs'}>
                <Col xs={12} md={8}>
                  <Heading as={'h2'} type={'heading3'} textAlign={'center'}>
                    {headline}
                  </Heading>
                </Col>
              </Row>
              {logos.length > 0 && (
                <Flex mt={[6, 7, 8]} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
                  {logos.map((l, i) => (
                    <Box
                      key={l.originalId || `logo-${i}`}
                      flex={['0 0 72px', '0 0 auto']}
                      my={[3, 3, 0]}
                      ml={i > 0 ? [3, 3, 4] : undefined}
                      mr={i + 1 < logos.length ? [3, 3, 4] : undefined}
                    >
                      <Image
                        alt={l.alt || ''}
                        title={l.title || l.alt || ''}
                        image={l.gatsbyImageData}
                        objectFit={'contain'}
                      />
                    </Box>
                  ))}
                </Flex>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default LogoList
